<template>
  <admin>
    <metatag title="Cars"></metatag>

    <page-header>
      <template v-slot:action>
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          large
          color="primary"
          exact
          :to="{ name: 'cars.create' }"
        >
          <v-icon small left>mdi-plus-circle</v-icon>
          <span v-text="'Add new'"></span>
        </v-btn>
      </template>
    </page-header>
    <v-card>
      <toolbar-menu
        ref="toolbar"
        :filter-model="true"
        :items.sync="tabletoolbar"
        @update:search="setSearch"
        @update:model="handleFilterModel"
      ></toolbar-menu>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="cars.headers"
          :items="items"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :items-per-page="5"
          color="primary"
          item-key="id"
          class="text-no-wrap"
        >
          <template v-slot:item.model="{ item }">
            <span>{{ item.model.name }}</span>
          </template>
          <template v-slot:item.year="{ item }">
            <span>{{ item.year.name }}</span>
          </template>
          <template v-slot:item.price="{ item }">
            <span>{{ formatPrice(item.price) }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip v-if="item.status == true" class="ma-2" color="green" text-color="white">
              active
            </v-chip>
            <v-chip v-else class="ma-2" color="red" text-color="white"> inactive </v-chip>
          </template>
          <!-- Action buttons -->
          <template v-slot:item.actions="{ item }">
            <div class="text-no-wrap">
              <!-- Preview -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                    :to="{
                      name: 'cars.variant-colors.index',
                      params: { id: item.id },
                    }"
                  >
                    <v-icon small>mdi-car-estate</v-icon>
                  </v-btn>
                </template>
                <span v-text="'View Colors'"></span>
              </v-tooltip>
              <!-- Preview -->
              <!-- Edit -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                    :to="{
                      name: 'cars.edit',
                      params: { id: item.id },
                    }"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Edit'"></span>
              </v-tooltip>
              <!-- Edit -->
              <!-- Delete -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text icon @click="handleDeleteVariant(item.id)">
                    <v-icon small>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Delete'"></span>
              </v-tooltip>
              <!-- Delete -->
            </div>
          </template>
          <!-- Action buttons -->
        </v-data-table>
      </v-card-text>
    </v-card>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";

export default {
  data() {
    return {
      filters: [
        { text: "Model", value: "model" },
        { text: "Variant", value: "name" },
        { text: "Year", value: "year" },
        { text: "Price", value: "price" },
        { text: "Status", value: "status" },
      ],
      tabletoolbar: {
        isSearching: false,
        search: null,
      },
      loading: true,
      options: {},
      items: [],
      total: 0,
      cars: {
        headers: [
          {
            text: "Model",
            align: "start",
            sortable: false,
            value: "model",
          },
          {
            text: "Variant",
            align: "start",
            sortable: false,
            value: "name",
          },
          {
            text: "Year",
            align: "start",
            sortable: false,
            value: "year",
          },
          {
            text: "Price",
            align: "start",
            sortable: false,
            value: "price",
          },
          {
            text: "Status",
            align: "start",
            sortable: false,
            value: "status",
          },
          {
            text: "Actions",
            align: "center",
            value: "actions",
            class: "muted--text text-no-wrap",
          },
        ],
        data: [],
      },
    };
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.getItems(page, itemsPerPage);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      data: "cars/GET_VARIANTS",
    }),
  },
  methods: {
    ...mapActions({
      getVariants: "cars/list",
      deleteVariant: "cars/delete",
      listYears: "years/list",
      showDialog: "dialog/show",
      hideDialog: "dialog/hide",
    }),

    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.getItems(page, itemsPerPage, e.target.value);
    }, 300),

    getItems(page, itemsPerPage, q = "", model = "") {
      let data = {
        page,
        per_page: itemsPerPage,
        q,
      };
      if (model) {
        data.model = model;
      }
      this.getVariants(data).then((data) => {
        this.items = this.data.data;
        this.total = this.data.meta.total;
        this.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },

    formatDate(item) {
      return helpers.format_date(item);
    },

    formatPrice(item) {
      return helpers.format_price(item);
    },

    handleDeleteVariant(id) {
      this.showDialog({
        title: trans("Delete Car"),
        text: trans("Are you sure you want to delete this car?"),
        buttons: {
          cancel: {
            text: trans("Cancel"),
            callback: () => {
              this.hideDialog();
            },
          },
          action: {
            color: "error",
            text: trans("Delete"),
            callback: () => {
              this.confirmDeleteVariant(id);
              this.hideDialog();
            },
          },
        },
      });
    },

    async confirmDeleteVariant(id) {
      this.loading = true;
      await this.deleteVariant(id);
      await this.getItems(1, 5, "");
      this.loading = false;
    },

    async handleFilterModel(val) {
      await this.getItems(1, 5, "", val);
    },
  },
};
</script>

<style></style>
